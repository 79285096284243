<template>
	<v-ons-page class="sorry-page">
		<!-- 定額用 -->
		<common-header
			v-if="!isWebview"
			:classObject="{ 'is-noicon': true }"
		>
			<h1 class="common-header-title__heading"><a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })">頭痛ーる&thinsp;forスゴ得</a></h1>
		</common-header>
		<div v-if="!isWebview" class="zutool-sorryError">
			<div class="zutool-sorryError__mainBox">
				<div class="zutool-sorryError__mainBox--textImage">
					<p v-html="text" />
					<img src="../assets/img/404_img01.png" alt="ごめんね...">
				</div>
			</div>
		</div>

		<!-- Webview用 -->
		<div v-else class="sorry-page__webview">
			<div class="sorry-page__webview__inner">
				<p class="sorry-page__webview__img"><img src="@/assets/img/data_error_mojaneko.png" alt="もじゃねこ"></p>
				<p class="sorry-page__webview__title">データを正常に<br>取得できませんでした。</p>
				<p class="sorry-page__webview__text">インターネットの接続状況を<br>ご確認いただくか、しばらく時間を<br>おいてから再度お試しください。</p>
				<a href="javascript:void(0);" @click="reload" class="sorry-page__webview__button">再読み込み</a>
			</div>
		</div>
	</v-ons-page>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'SorryPage',
	props: {
		text: {
			type: String,
			default: ''
		}
	},
	components: {
		CommonHeader
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isWebview'])
	},
	mounted () {
		// コンテンツの高さをemitする。
		const containerHeight = window.innerHeight - cmnConst.HEADER_HEIGHT - cmnConst.SUGOTOKU_HEADER_HEIGHT - cmnConst.SUGOTOKU_FOOTER_HEIGHT
		this.$nextTick(() => {
			this.$emit('containerHeight', containerHeight)
		})
	},
	beforeDestroy () {
		// コンテンツの高さをautoでemitする。
		this.$emit('containerHeight', 'auto')
	},
	methods: {
		reload () {
			this.$emit('reload')
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

.zutool-sorryError {
	width: 100%;
	height: 100%;
	background: $background-primary;
	&__mainBox {
		width: 100%;
		height: 100%;
		position: relative;
		&--textImage {
			padding: 0 $font-size-10;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;
			p {
				color: $primary;
				font-family: $family-primary;
				font-size: $font-size-20;
				font-weight: bold;
				line-height: $line-height;
				margin: $spacing-20 auto;
			}
			img {
				width: 50%;
				margin: 0;
			}
		}
	}
}

// 以下、Webview用のレイアウト
// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$line-height-13: 1.3;
$spacing-12: 0.75rem;

.sorry-page__webview {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: $background-primary;
	width: 100%;
	height: 100%;
	z-index: 9999;

	&__inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: -44px;	// ヘッダーの高さ分のネガティブマージン

		& > p,
		& > a  { text-align: center; }
	}

	&__img {
		width: 31.25%;
		margin: 0 auto;

		& > img { width: 100%; }
	}

	&__title {
		margin: $spacing-6 0 0;
		font-family: $lp-family-tertiary;
		font-size: $font-size-18;
		line-height: $line-height-13;
	}

	&__text {
		margin: $spacing-6 0 0;
		font-family: $lp-family-primary;
		font-size: $font-size-14;
	}

	&__button {
		display: block;
		width: 53.125%;
		padding: $spacing-12 $spacing-10;
		margin: $spacing-26 auto 0;
		font-family: $lp-family-primary;
		color: $text-seconday;
		background: $button-primary;
		font-size: $font-size-16;
		text-decoration: none;
		line-height: $line-height-1;
		border-radius: 10px;
		box-sizing: border-box;
	}
}
</style>
